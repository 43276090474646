import logo from 'assets/images/hero-logo.svg';

export default function Hero() {
  return (
    <div className="hero">
      <div className="container p-8">
        <img src={logo} alt="Kollectiff" />
      </div>
    </div>
  )
}