import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import jsonp from 'jsonp';
import 'assets/styles/Footer.css';
import SocialLink from 'components/SocialLink';
import video from 'assets/videos/footer.mp4';
import { ReactComponent as Spinner } from 'assets/images/spinner.svg';

export default function Footer() {
  const { navigation, social, mailchimp } = require('configs/settings.json');
  const [status, setStatus] = useState('idle');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors } } = useForm({ mode: 'onChange' });
  const currentYear = new Date().getFullYear();

  const onSubmit = data => {
    setStatus('loading');

    jsonp(`${mailchimp.url}&EMAIL=${data.email}`, { param: 'c' }, (_, data) => {
      const { result } = data;

      if (result === 'success') {
        setStatus('success');
      } else {
        setStatus('idle');
      }
    });
  }

  return (
    <footer>
      <div className="container flex flex-col gap-16 py-[120px] px-8">
        <div>
          <video autoPlay playsInline muted loop className="w-full max-w-[770px]">
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-8">
          <div className="lg:col-span-4 xl:col-span-3 flex flex-col gap-8 mr-auto">
            <motion.h3
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}>
              A Creative Engineering Company
            </motion.h3>
            <div>
              <ul>
                {navigation.footer.map((item) =>
                  <li key={item.link}>
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="flex gap-6">
              {social.map(props =>
                <SocialLink key={props.title} {...props} />
              )}
            </div>
          </div>
          <div className="lg:col-span-6 xl:col-span-4 lg:col-start-7 xl:col-start-9 flex flex-col gap-8">
            <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="newsletter">Stay updated</label>
              <div className="relative">
                <input
                  type="email"
                  id="newsletter"
                  disabled={status === 'loading' || status === 'success'}
                  placeholder="Sign up for our newsletter"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                    }
                  })} />
                <button
                  type="submit"
                  className={classNames({ '!text-kollectiff-lime': !errors.email && watch('email') })}
                  disabled={status === 'loading' || status === 'success'}>
                  <AnimatePresence mode="wait">
                    {status === 'idle' && (
                      <motion.span
                        key="submit"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                      >
                        submit
                      </motion.span>
                    )}
                    {status === 'loading' && (
                      <motion.span
                        key="spinner"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                      >
                        <Spinner className="fill-kollectiff-lime" />
                      </motion.span>
                    )}
                    {status === 'success' && (
                      <motion.span
                        className="text-kollectiff-lime"
                        key="suscribed"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                      >
                        suscribed
                      </motion.span>
                    )}
                  </AnimatePresence>
                </button>
              </div>
            </form>
            <div>
              <span className="text-base font-light">
                KOLLECTIFF™ {currentYear} | All right reserved ©
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
