import 'assets/styles/Hamburger.css';
import classNames from 'classnames';

export default function Hamburguer({ onClick, active }) {
  return (
    <button
      type="button"
      className="block lg:hidden"
      onClick={onClick}>
      <div className={classNames('hambuguer', { 'active': active, 'inactive': !active })}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  );
}