import loader from 'assets/videos/loader.mp4';

export default function LoadingScreen() {
  return (
    <div className="h-svh w-full flex items-center justify-center">
      <video autoPlay playsInline muted loop className="w-1/3 h-1/3">
        <source src={loader} type="video/mp4" />
      </video>
    </div>
  );
}
