import { useState } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';

export default function Filter({ tag, onClick }) {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(prev => !prev);
    onClick(onClick);
  }

  return (
    <motion.button
      type="button"
      className={classNames('filter', { active })}
      onClick={handleClick}
      whileTap={{ scale: 0.8 }}>
      {tag}
    </motion.button>
  );
}
