import { domAnimation, LazyMotion, motion } from 'framer-motion';

export default function AnimationPage({ children }) {
  return (
    <LazyMotion features={domAnimation}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        {children}
      </motion.div>
    </LazyMotion>
  )
}
