const animations = {
  nav: {
    desktop: {
      container: {
        loaded: {
          transition: {
            staggerChildren: 0.2,
            delayChildren: 0.2
          }
        },
        unloaded: {}
      },
      child: {
        loaded: { opacity: 1 },
        unloaded: { opacity: 0 },
      }
    }
  },
  components: {
    opacityAndSlide: {
      initial: { opacity: 0, y: -10 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true }
    },
    opacityAndMove: {
      initial: { opacity: 0, y: -50 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true }
    },
    opacity: {
      initial: { opacity: 0 },
      whileInView: { opacity: 1 },
      viewport: { once: true }
    }
  }
}

export default animations;