import { useEffect, useState } from 'react';

export default function SocialLink(props) {
  const [src, setSrc] = useState('');
  const [defaultImage, setDefaultImage] = useState('');
  const [hoverImage, setHoverImage] = useState('');

  useEffect(() => {
    const loadImage = async (imageName) => {
      const image = await import(`assets/images/${imageName}.svg`);

      return image.default;
    };

    const initImages = async () => {
      const defaultImage = await loadImage(props.image);
      const hoverImage = await loadImage(props.hoverImage);

      return { defaultImage, hoverImage };
    }
 
    initImages().then(({ defaultImage, hoverImage }) => {
      setSrc(defaultImage);
      setDefaultImage(defaultImage);
      setHoverImage(hoverImage);
    });
  }, [props.image, props.hoverImage]);

  return (
    <a 
      href={props.link}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={() => { setSrc(hoverImage) }} 
      onMouseLeave={() => { setSrc(defaultImage) }}
      onFocus={() => { setSrc(hoverImage) }}>
      {defaultImage && <img src={src} alt={props.title} width={32} height={32} />}
    </a>
  );
}
