import { motion } from 'framer-motion';
import animations from 'utils/animations';
import Seo from 'components/Seo';
import AnimationPage from 'components/AnimationPage';

export default function OurValues() {
  const { principles } = require('data/our-values.json');
  const { opacity, opacityAndSlide } = animations.components;

  return (
    <AnimationPage>
      <Seo title="Our Values - Kollectiff" />
      <div className="container mt-[89px] pb-16 px-8">
        <div className="py-16">
          <motion.h1
            {...opacity}
            transition={{ delay: .2 }}>
            Introducing our foundational<br /> core principles
          </motion.h1>
        </div>
        <div className="flex flex-col gap-8 lg:w-8/12">
          {principles.map(principle =>
            <motion.div 
              {...opacityAndSlide}
              transition={{ delay: .3 }}
              key={principle.title} 
              className="principle">
              <h4>{principle.title}</h4>
              <p className="!text-xl font-semibold">{principle.subtitle}</p>
              <p>{principle.description}</p>
            </motion.div>
          )}
        </div>
      </div>
    </AnimationPage>
  );
}
