import { motion } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import animations from 'utils/animations';
import Media from 'components/Media';

export default function Section({ name, id, customClass, title, description, media, children }) {
  const { opacity, opacityAndMove } = animations.components;

  return (
    <div id={id} className={customClass}>
      <div className="container px-8 py-20 lg:py-20 xl:py-40">
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <div className="lg:col-span-7 lg:col-start-2 flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <motion.span 
                {...opacityAndMove}
                transition={{ delay: .1}}
                className="uppercase text-kollectiff-lime text-xs lg:text-base">
                  {name}
              </motion.span>
              <motion.h2 
                {...opacity}
                transition={{ delay: .2 }}
                className="font-semibold">
                  {title}
              </motion.h2>
            </div>
            <div className="flex flex-col gap-4">
              <motion.div 
                {...opacity}
                transition={{ delay: .3 }}
                className="dark:text-white flex flex-col gap-4 text-xl">
                <PortableText value={description} />
              </motion.div>
              {children}
            </div>
          </div>
        </div>
      </div>
      {(media.leftColumnMedia?.source || media.rightColumnMedia?.source) && (
        <div className="container px-8 pb-8 lg:pb-16">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <div className="lg:col-span-5 lg:col-start-2">
              <Media source={media.leftColumnMedia.source} controls={media.leftColumnMedia.controls} />
            </div>
            <div className="lg:col-span-5">
              <Media source={media.rightColumnMedia.source} controls={media.rightColumnMedia.controls} />
            </div>
          </div>
        </div>
      )}
      {media.fullWidthMedia.source && (
        <div>
          <Media source={media.fullWidthMedia.source} controls={media.fullWidthMedia.controls} />
        </div>
      )}
    </div>
  );
}
