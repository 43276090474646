import Seo from 'components/Seo';
import AnimationPage from 'components/AnimationPage';

export default function PrivacyPolicy() {
  return (
    <AnimationPage>
      <Seo title='Privacy Policies - Kollectiff' />
      <div className="container mt-[89px] pb-16 px-8">
        <div className="grid grid-cols-12 privacy-policy">
          <div className="col-span-8">
            <div className="py-16">
              <h1>Privacy Policy</h1>
            </div>
            <ul className="list-decimal text-kollectiff-lime list-inside text-[28px] font-bold gap-5 flex flex-col">
              <li>Introduction</li>
              <div>
                <div>
                  <span className="text-xl">Who are we?</span>
                  <p>We are The Motiff Group Inc. (hereinafter “Kollectiff”), a company registered under company number 6020061 and registered office at 131 Continental Dr., Suite 305, Newark, DE 19713. When we decide the means or purpose of processing your personal data, we are the data "controller." We operate the website https://kollectiff.com/.</p>
                </div>
                <div>
                  <span className="text-xl">What’s this policy about?</span>
                  <p>This policy explains how we process your personal data as a data controller, relating to:</p>
                  <ul className="list-disc list-inside text-base font-normal pl-3 dark:text-white">
                    <li>Individuals who visit this website or any of our other websites (“Website Visitors”); and</li>
                    <li>Suppliers or freelancers who we engage to deliver services to us or on our behalf, or who we communicate with in relation to them prospectively delivering any such services to us or on our behalf (“Suppliers”).</li>
                    <li>People applying for employment with Kollectiff (“Employment Applicants”).</li>
                  </ul>
                  <p>We update this policy from time to time so please check back in.</p>
                </div>
                <div>
                  <span className="text-xl">How do you contact us?</span>
                  <p>If you have questions about this policy or to exercise your rights, write to us at <a href="mailto:hello@kollectiff.com" className="text-kollectiff-lime underline">hello@kollectiff.com</a>. Please add in the subject ‘Privacy Policy’ so we filter inbound emails appropriately, and connect you with the right team.</p>
                </div>
                <div>
                  <span className="text-xl">What are your rights?</span>
                  <p>You have the following rights, although these rights may be limited in some circumstances:</p>
                  <ul className="list-decimal list-inside text-base font-normal pl-3 dark:text-white">
                    <li>Ask us to send a copy of data to you or someone else. Ask us to restrict, stop processing, or delete your data.</li>
                    <li>Object to our processing of your data.</li>
                    <li>Object to use of your personal data for direct marketing.</li>
                    <li>Ask us to correct inaccuracies.</li>
                  </ul>
                  <p>If we rely on consent to process data, or send direct marketing, you can withdraw consent by contacting us via the link above.</p>
                </div>
              </div>
              <li>You personal data and how we use it</li>
              <div>
                <div>
                  <p>Where explicit retention periods are not described below, we hold data for as long as necessary bearing in mind the purpose for which it was collected. At the end of the retention period, we assess whether it is necessary to continue to retain Personal Information to achieve the purposes for which it was collected. To determine appropriate retention periods, we consider the amount, nature, and sensitivity of the data, the potential risk of unauthorized access, and legal requirements.</p>
                  <p>Personal Information includes, but is not limited to the following:</p>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </AnimationPage>
  );
}
