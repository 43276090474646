import { Helmet } from 'react-helmet-async';

export default function Seo({ title, description, image }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="@kollectiff" />
      <meta name="twitter:creator" content="@kollectiff" />
    </Helmet>
  );
}
