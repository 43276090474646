import { client } from 'configs/sanity';

const works = async () => {
  try {
    const response = await client.fetch(`*[_type == "work"] | order(orderRank) {
      "tags": tags[]->tag,
      "slug": slug.current, 
      "title": preview.title, 
      "description": preview.description, 
      "media": preview.media.asset->url
    }`);

    return response;
  } catch (error) {
    console.error('Error fetching works:', error);
    return [];
  }
}

const work = async (params) => {
  try {
    const response = await client.fetch(`{
      "next": coalesce(
        *[_type == "work" && orderRank > *[_type == "work" && slug.current == ${params.slug}][0].orderRank] | order(orderRank asc)[0].slug.current,
        *[_type == "work"] | order(orderRank asc)[0].slug.current
      ),
      "work": *[_type == "work" && slug.current == "${params.slug}"][0]{
        title,
        client,
        "tags": tags[]->tag,
        "heroBackground": heroBackground.asset->url,
        "hero": {
          "image": heroBackground.asset->url
        },
        seo {
          title,
          description,
          "image": image.asset->url
        },
        new {
          link,
          source,
          title,
          "backgroundColor": backgroundColor.hex
        },
        overview {
          title,
          description,
          project,
          services,
          "media": {
            "fullWidthMedia": {
              "source": media.file.asset->url,
              "controls": media.controls        
            }
          }
        },
        approach {
          title,
          description,
          "media": {
            "fullWidthMedia": {
              "source": media.file.asset->url,
              "controls": media.controls        
            },
            "leftColumnMedia": {
              "source": mediaColumnLeft.file.asset->url,
              "controls": mediaColumnLeft.controls
            },
            "rightColumnMedia": {
              "source": mediaColumnRight.file.asset->url,
              "controls": mediaColumnRight.controls
            }
          }
        },
        challenge {
          title,
          description,
          "media": {
            "fullWidthMedia": {
              "source": media.file.asset->url,
              "controls": media.controls        
            },
            "leftColumnMedia": {
              "source": mediaColumnLeft.file.asset->url,
              "controls": mediaColumnLeft.controls
            },
            "rightColumnMedia": {
              "source": mediaColumnRight.file.asset->url,
              "controls": mediaColumnRight.controls
            }
          }
        },
        feature {
          title,
          description,
          "media": {
            "fullWidthMedia": {
              "source": media.file.asset->url,
              "controls": media.controls        
            }
          }
        },
        recognitions[] {
          title,
          description,
          link,
          "logo": logo.asset->url
        }
      }
    }`);

    return response;
  } catch (error) {
    console.error('Error fetching work:', error);
    return [];
  }
}

const home = async () => {
  try {
    const response = await client.fetch(`*[_id == "home"]{
      works[]->{
        "tags": tags[]->tag,
        "slug": slug.current,
        preview {
          description,
          title,
          "media": media.asset->url
        }
      }
    }`);
  
    return response; 
  } catch (error) {
    console.error('Error fetching works:', error);
    return [];
  }
}

export { works, work, home };
