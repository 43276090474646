import Layout from 'components/Layout';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { work, works, home } from 'utils/api';
import About from 'routes/about';
import CaseStudy from 'routes/case-study';
import Contact from 'routes/contact';
import Home from 'routes/home';
import OurValues from 'routes/our-values';
import PrivacyPolicy from 'routes/privacy-policy';
import Work from 'routes/work';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
        loader: home
      },
      {
        path: 'work',
        element: <Work />,
        loader: works
      },
      {
        path: 'work/:slug',
        element: <CaseStudy />,
        loader: ({ params }) => work(params)
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'our-values',
        element: <OurValues />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      }
    ]
  }
]);

export default function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}
