import { motion } from 'framer-motion';
import { Link, useLoaderData } from 'react-router-dom';
import animations from 'utils/animations';
import Seo from 'components/Seo';
import WorkPreview from 'components/WorkPreview';
import AnimationPage from 'components/AnimationPage';
import video from 'assets/videos/about-us.mp4';

export default function Home() {
  const home = useLoaderData();
  const { opacity } = animations.components;

  return (
    <AnimationPage>
      <Seo title='Kollectiff - A Creative Engineering Company' />
      <div className="container px-8 py-12">
        <div className="flex justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:w-2/3 gap-y-8">
            <div className="flex flex-col gap-8 lg:gap-12 justify-center lg:-mr-48 lg:z-20">
              <motion.h2
                {...opacity}
                transition={{ delay: .2 }}>
                We are a global team of creators pioneering emerging technologies in the realms of interactive, mixed-realities, AI and gaming.
              </motion.h2>
              <motion.div
                {...opacity}
                transition={{ delay: .3 }}>
                <Link to="/about" className="read-more">about us</Link>
              </motion.div>
            </div>
            <div className="video-square relative">
              <div className="absolute z-10 w-full h-full bg-black bg-opacity-30"></div>
              <video autoPlay playsInline muted loop>
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="pt-[120px] pb-20 grid grid-cols-1 lg:grid-cols-12 gap-8 gap-y-[60px] lg:gap-y-20">
          {home[0].works.map((work, index) => (
            <WorkPreview
              key={index}
              media={work.preview.media}
              title={work.preview.title}
              description={work.preview.description}
              link={work.slug}
              tags={work.tags} />
          ))}
        </div>
      </div>
    </AnimationPage>
  )
}
