import { motion } from 'framer-motion';
import animations from 'utils/animations';

export default function CityCard({ data }) {
  const { opacity } = animations.components;

  return (
    <motion.div 
      className="city"
      {...opacity}
      transition={{ delay: .2 }}>
      <img src={require(`assets/images/${data.image}`)} alt="" />
      <div className="city-content">
        <h3>{data.city}, {data.country}</h3>
        <span className="text-[#828282]">{data.timezone}</span>
        {data.contacts.map(contact =>
          <div className="city-contact" key={contact.email}>
            <span className="dark:text-white">{contact.name}</span>
            <a href={`mailto:${contact.email}`} className="text-[#828282]">{contact.email}</a>
          </div>
        )}
      </div>
    </motion.div>
  );
}
