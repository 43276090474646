// import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import classNames from 'classnames';
import Media from 'components/Media';
import 'assets/styles/WorkPreview.css';

export default function WorkPreview({ media, tags, title, description, link, section }) {
  return (
    <div className={classNames('work-preview', { 'works': section })}>
      <div>
        <Media source={media} />
      </div>
      <div className="flex flex-col gap-3">
        <div>
          {tags.map(tag => <span key={tag} className="tag">{tag}</span>)}
        </div>
        <div className="flex flex-col gap-1">
          <motion.h3
            initial={{ opacity: 0, y: -10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: .2 }}>{title}</motion.h3>
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: .3 }}>
            <PortableText value={description} />
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: .4 }}>
          <Link to={`/work/${link}`} className="read-more text-base">read more</Link>
        </motion.div>
      </div>
    </div >
  );
}
