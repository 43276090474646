import { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import icon from 'assets/images/icon-error.svg';

const ErrorMessage = ({ message, error }) => (
  <AnimatePresence>
    {error && (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="absolute -bottom-8 text-kollectiff-red w-full pt-3 pb-1 text-base rounded-l-sm rounded -z-10 flex gap-1"
      >
        <img src={icon} width={16} height={16} alt="" />
        {message}
      </motion.div>
    )}
  </AnimatePresence>
);

export default memo(ErrorMessage);
