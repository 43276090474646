import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import animations from 'utils/animations';
import classNames from 'classnames';
import 'assets/styles/Nav.css';
import logo from 'assets/images/logo-white.svg';
import Hamburguer from 'components/Hamburger';

export default function Nav({ isHome }) {
  const { navigation } = require('configs/settings.json');
  const { container, child } = animations.nav.desktop;
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scroll, setScroll] = useState(0);

  const handleOnClick = () => {
    if (!isOpen) {
      setScrollPosition(window.scrollY);
    }
    setIsOpen(prev => !prev);
  }

  const NavLinks = ({ mobile }) => {
    return (
      <motion.ul
        variants={container}
        initial="unloaded"
        animate="loaded">
        {navigation.main.map(item => (
          <motion.li
            key={item.link}
            variants={child}>
            <NavLink end to={item.link} {...(mobile && { onClick: handleOnClick })}>
              {item.text}
            </NavLink>
          </motion.li>
        ))}
      </motion.ul>
    )
  }

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', isOpen);

    if (isOpen) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, scrollPosition);
    }
  }, [isOpen, scrollPosition]);

  useEffect(() => {
    const handleScroll = () => {
      if (isHome) {
        setScroll(window.scrollY > window.innerHeight - 88);
      }
    }

    window.addEventListener('scroll', handleScroll);
  }, [isHome]);

  return (
    <nav {...((scroll || !isHome) && { className: 'sticky' })}>
      <div className="container flex px-8 py-6">
        <div className="mr-auto">
          <AnimatePresence mode="wait">
            {isHome ? (
              scroll
                ? <motion.img
                  key='logo'
                  src={logo}
                  alt="Kollectiff"
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -15 }} />
                : <motion.div
                  key='tagline'
                  className="font-manrope text-lg dark:text-white"
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -15 }}>
                  A Creative Engineering Company
                </motion.div>
            ) : (
              <Link to="/">
                <img src={logo} alt="Kollectiff" />
              </Link>
            )}
          </AnimatePresence>
        </div>
        <div className="flex items-center">
          <NavLinks />
          <Hamburguer active={isOpen} onClick={handleOnClick} />
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className={classNames('nav-mobile', { 'nav-home': isHome, 'nav-section': !isHome })}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <NavLinks mobile />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
}
